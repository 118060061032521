<template>
    <div>
        <b-card>
            <b-card-header header-class="pt-0">
                <h4>تفاصيل المادة {{ subjectDetailDto.name }}</h4>
            </b-card-header>
            <b-card-body>
                <ValidationObserver ref="form">
                    <b-row>
                        <b-col lg="4">
                            <ek-input-text
                                name="name"
                                label=" اسم المادة "
                                placeholder="ادخل اسم المادة"
                                v-model="subjectDetailDto.name"
                            >
                            </ek-input-text>
                            <b-row no-gutters>
                                <b-col lg="6" md="12">
                                    <ek-input-select
                                        :options="years"
                                        v-model="subjectDetailDto.yearId"
                                        placeholder="السنة"
                                        name=" اختر السنة"
                                        label="السنة"
                                        :rules="[
                                            {
                                                type: 'required',
                                                message: 'السنة مطلوبة',
                                            },
                                        ]"
                                    >
                                    </ek-input-select>
                                </b-col>
                                <b-col lg="6" md="12" class="pl-2">
                                    <ek-input-select
                                        :options="semesters"
                                        placeholder="اختر الفصل"
                                        name=" اختر الفصل"
                                        label="الفصل"
                                        v-model="subjectDetailDto.semesterId"
                                        :rules="[
                                            {
                                                type: 'required',
                                                message: 'الفصل مطلوب',
                                            },
                                        ]"
                                    >
                                    </ek-input-select>
                                </b-col>
                            </b-row>
                            <ek-input-text
                                name="name"
                                label=" سعر المادة "
                                v-model="subjectDetailDto.price"
                                placeholder="سعر المادة"
                            >
                            </ek-input-text>
                            <ek-input-text
                                name="name"
                                label=" علامة المادة "
                                v-model="subjectDetailDto.mark"
                                placeholder="علامة المادة"
                            >
                            </ek-input-text>
                            <ek-input-text
                                readonly
                                type="number"
                                name="name"
                                label=" عدد المشتركين "
                                placeholder="عدد المشتركين"
                                v-model="subjectDetailDto.subscribersCount"
                            >
                            </ek-input-text>
                        </b-col>

                        <b-col lg="4">
                            <ek-input-select
                                :rules="[
                                    {
                                        type: 'required',
                                        message:
                                            '  يرجى اختيار الجامعة/المرحلة التعليمية',
                                    },
                                ]"
                                :options="universityNames"
                                name="classes"
                                v-model="subjectDetailDto.universityId"
                                placeholder="تابع للجامعة"
                                :label="
                                    tab === '0'
                                        ? 'الجامعة'
                                        : 'المرحلة التعليمية'
                                "
                                clearable
                                @change="resetUniversity"
                            ></ek-input-select>
                            <ek-input-select
                                v-if="subjectDetailDto.universityId"
                                :rules="[
                                    {
                                        type: 'required',
                                        message: '  يرجى اختيار كلية/الصف',
                                    },
                                ]"
                                :options="
                                    universityNames.find(
                                        (u) =>
                                            u.id ==
                                            subjectDetailDto.universityId
                                    ).faculties
                                "
                                name="classes"
                                v-model="subjectDetailDto.facultyId"
                                :label="tab === '0' ? 'الكلية' : 'الصف'"
                                clearable
                                @change="resetFaculty"
                            ></ek-input-select>

                            <ek-input-select
                                v-if="subjectDetailDto.facultyId"
                                :options="
                                    universityNames
                                        .find(
                                            (u) =>
                                                u.id ==
                                                subjectDetailDto.universityId
                                        )
                                        .faculties.find(
                                            (f) =>
                                                f.id ==
                                                subjectDetailDto.facultyId
                                        ).departments
                                "
                                clearable
                                placeholder="تابعة للفرع"
                                name=" اختر الفرع"
                                label="الفرع"
                                v-model="subjectDetailDto.departmentId"
                                :rules="[
                                    {
                                        type: 'required',
                                        message: 'الفرع مطلوب',
                                    },
                                ]"
                            >
                            </ek-input-select>
                        </b-col>
                        <b-col lg="4" class="position-relative">
                            <b-dropdown
                                size="lg"
                                variant="link"
                                toggle-class="text-decoration-none"
                                no-caret
                                class="position-absolute"
                            >
                                <template #button-content>
                                    <unicon
                                        name="ellipsis-v"
                                        fill="#2EC4B6"
                                    ></unicon>
                                </template>
                                <b-dropdown-item
                                    href="#"
                                    ref="myUploader"
                                    @click.stop="fileWindowApi(onUpload)"
                                >
                                    <unicon
                                        name="edit-alt"
                                        fill="#2EC4B6"
                                        style="width: 25px"
                                    ></unicon>
                                    <span>تعديل الصورة</span>
                                </b-dropdown-item>
                            </b-dropdown>
                            <img
                                class="w-100 rounded"
                                style="object-fit: cover; height: 250px"
                                :src="
                                    $store.getters['app/domainHost'] +
                                    '/' +
                                    subjectDetailDto.url
                                "
                                alt=""
                            />
                        </b-col>
                        <b-col lg="12">
                            <ek-input-textarea
                                name="name"
                                label="شرح المادة"
                                placeholder="شرح المادة"
                                v-model="subjectDetailDto.description"
                            >
                            </ek-input-textarea>
                        </b-col>
                    </b-row>
                </ValidationObserver>
            </b-card-body>
        </b-card>
        <div class="d-flex justify-content-end align-items-center">
            <div class="d-flex justify-content-center align-items-center">
                <ek-input-text
                    v-model="searchVal"
                    name="ابحث عن كورس محدد"
                    placeholder="ابحث عن كورس محدد"
                    @input="filterSearch"
                >
                </ek-input-text>
                <AddCourse
                    class="ml-2"
                    @fillSelectCourse="
                        $store.commit('Filter_Course', subjectDetailDto.units)
                    "
                ></AddCourse>
            </div>
        </div>

        <ek-table
            :columns="columns"
            :items="filterCourses"
            @details="detailsCourse"
            :no_select="true"
            :no_delete="true"
        >
            <template #table-header>
                <div class="d-flex p-1">
                    <unicon class="pr-1" fill="gray" name="bookmark"></unicon>
                    <h4>وحدات المادة</h4>
                </div>
            </template>

            <template slot="items.dateCreated" slot-scope="{ value }">
                {{ value ? new Date(value).toLocaleDateString() : "_" }}
            </template>
        </ek-table>
    </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { fileWindowApi } from "@core/utils/uploader";
import AddCourse from "@admin/courses/components/AddCourse.vue";

export default {
    props: {
        id: String,
    },
    components: {
        AddCourse,
    },
    data: () => ({
        tab: "0",
        searchVal: "",
        isCourse: true,

        columns: [
            {
                label: "اسم الوحدة ",
                field: "name",
            },
            {
                label: "عدد الفصول",
                field: "chapterCount",
            },
            {
                label: "عدد الطلاب المسجلين",
                field: "studentCount",
            },
            {
                label: "تاريخ الاضافة",
                field: "dateCreated",
            },
            {
                label: "تفاصيل",
                field: "details",
            },
        ],
    }),
    computed: {
        ...mapState({
            subjectDetailDto: (state) => state.subjects.subjectDetailDto,
            universityNames: (state) => state.university.universityName,
            years: (state) => state.settings.yearList,
            semesters: (state) => state.settings.semesterList,
        }),
        ...mapGetters(["filterCourses"]),
    },
    methods: {
        ...mapActions([
            "getSubjectDetails",
            "updateSubject",
            "deleteSubject",
            "addFileSubject",
        ]),
        fileWindowApi,
        onUpload(e) {
            console.log("onUpload");
            if (e.file) {
                this.addFileSubject({ ...e, folderName: "Profile" }).then((res) => {
                    this.subjectDetailDto.url = res.data;
                });
            }
        },
        resetUniversity() {
            this.subjectDetailDto.facultyId = "";
        },
        resetFaculty() {
            this.subjectDetailDto.departmentId = "";
        },
        detailsCourse({ row }) {
            this.$router.push(`/admin/unit/${row.id}`);
        },
        filterSearch() {
            this.$store.commit("Filter_Course", this.searchVal);
        },
    },
    // beforeDestroy() {
    //   this.$store.commit("Set_Subjects_Dto");
    // },
    created() {
        this.getSubjectDetails(this.id).then(() => {
            this.$store.commit("Fill_Select_Course", this.subjectDetailDto);
        });
    },
    beforeDestroy() {
        this.$store.commit("Reset_Course");
    },
    mounted() {
        this.tab = this.$route.query.tab;
    },
};
</script>
<style lang="scss">
.dropdown.b-dropdown {
    right: 0 !important;
}
</style>

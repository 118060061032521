<template>
    <ek-dialog
        v-if="
            getUserRoles() == Admin ||
            getUserRoles() == SuperAdmin ||
            getUserRoles() == DataEntry
        "
        ref="addDialog"
        title="إضافة كورس"
        size="md"
        btnText="اضافة كورس"
        @ok="submit()"
        @close="reset"
    >
        <template #body>
            <ValidationObserver ref="courseForm">
                <!-- {{ subjectDetailDto }} -->
                <!-- {{ courseDto }} -->
                <b-form>
                    <b-form-group label="حالة الكورس" v-slot="{ courseStatus }">
                        <b-form-radio-group
                            id="radio-group-2"
                            v-model="courseDto.isFree"
                            :aria-describedby="courseStatus"
                            name="radio-sub-component"
                        >
                            <b-form-radio :value="true">مجاني</b-form-radio>
                            <b-form-radio :value="false">مدفوع</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                    <!-- </div> -->
                    <ek-input-text
                        :rules="[
                            { type: 'required', message: '  اسم الكورس مطلوب' },
                        ]"
                        name="name"
                        placeholder=" ادخل اسم الكورس"
                        label="  اسم الكورس"
                        v-model="courseDto.name"
                    >
                    </ek-input-text>
                    <!-- جامعة كلية فرع  -->
                    <ek-input-select
                        name="الفرع"
                        label=" تابع ل جامعة - كلية - فرع "
                        placeholder="جامعة - كلية - فرع"
                        :options="departmentsSubjectsOptions"
                        v-model="subjectDetailDto.departmentId"
                        @change="resetSubject"
                    >
                    </ek-input-select>
                    <ek-input-select
                        name="المادة"
                        v-model="subjectDetailDto.id"
                        v-if="subjectDetailDto.departmentId"
                        label=" تابع ل سنة - فصل - مادة "
                        placeholder="تابع ل سنة - فصل - مادة "
                        :options="
                            departmentsSubjectsOptions.find(
                                (d) => d.id == subjectDetailDto.departmentId
                            ).subjects
                        "
                    >
                    </ek-input-select>
                    <!-- {{ subjectDetailDto }} -->
                    <ek-input-select
                        :options="settingDto.teachers"
                        clearable
                        placeholder="مدرس الكورس"
                        name="مدرس الكورس"
                        label="مدرس الكورس"
                        v-model="courseDto.teacherId"
                    >
                    </ek-input-select>

                    <ek-input-textarea
                        :rules="[
                            { type: 'required', message: 'وصف الكورس مطلوب' },
                        ]"
                        name="name"
                        placeholder="وصف الكورس"
                        v-model="courseDto.description"
                        label="وصف الكورس"
                    >
                    </ek-input-textarea>
                    <ek-input-image
                        ref="imgFile"
                        label=" صورة الكورس "
                        name="صور "
                        @input="uploadSubFile"
                        accept="image/png, image/jpeg"
                    >
                        <h5>انقر لتحميل الصورة</h5>
                    </ek-input-image>
                </b-form>
            </ValidationObserver>
        </template>
    </ek-dialog>
    <!-- <ek-input-textarea
                        :rules="[
                            { type: 'required', message: 'وصف الكورس مطلوب' },
                        ]"
                        name="name"
                        placeholder="وصف الكورس"
                        v-model="courseDto.description"
                        label="وصف الكورس"
                    >
                    </ek-input-textarea>
                    <ek-input-image
                        ref="imgFile"
                        label=" صورة الكورس "
                        name="صور "
                        @input="uploadSubFile"
                    >
                        <h5>انقر لتحميل الصورة</h5>
                    </ek-input-image>
                </b-form>
            </ValidationObserver>
        </template> -->
    <!-- </ek-dialog> -->
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { mapState, mapActions } from "vuex";
import { getUserRoles } from "@/app/admin/auth/utils";
import { SuperAdmin, Admin, DataEntry } from "@/router";

export default {
    components: {
        ValidationObserver,
    },
    // props: {
    //     subjectDetailDto: Object, // or the appropriate type
    // },
    data: () => ({ SuperAdmin, Admin, DataEntry }),
    computed: {
        ...mapState({
            subjectDetailDto: (state) => state.subjects.subjectDetailDto,
            filterDto: ({ courses }) => courses.filterDto,

            subjectList: ({ subjects }) => subjects.subjects,
            courseDto: ({ courses }) => courses.courseDto,
            url: ({ courses }) => courses.url,
            universityNames: ({ university }) => university.universityName,

            settingDto: ({ settings }) => settings.settingDto,
            years: ({ settings }) => settings.yearList,
            semesters: ({ settings }) => settings.semesterList,
            branchList: ({ branches }) => branches.branchList,
            departmentsSubjectsOptions: ({ courses }) =>
                courses.departmentsSubjectsOptions,
        }),
        // ...mapGetters(["getUrl"]),
    },
    methods: {
        ...mapActions([
            "addCourse",
            "getCourses",
            "getSettings",
            "getSettingYear",
            "getSettingSemester",
            "getBranchList",
            "getNamesUniversity",
            "addFileCourse",
            "getSubjectsList",
            "getDepartmentsWithSubjects",
        ]),
        getUserRoles,
        submit() {
            this.$refs["courseForm"].validate().then((success) => {
                if (success) {
                    this.courseDto.departmentId =
                        this.subjectDetailDto.departmentId;
                    this.courseDto.subjectId = this.subjectDetailDto.id;
                    this.addCourse({ ...this.courseDto, url: this.url }).then(
                        (data) => {
                            this.$store.commit("Add_Courses_Subjects", data);
                            this.getCourses(this.filterDto);
                            this.$refs.addDialog.close();
                            this.$store.commit("Reset_Course");
                            this.$emit("fillSelectCourse");
                        }
                    );
                }
            });
        },
        resetSubject() {
            this.courseDto.subjectId = "";
        },

        reset() {
            this.$refs.courseForm.reset();
            this.$refs.imgFile.reset();

            this.$refs.addDialog.close();
        },
        uploadSubFile(event) {
            if (event && event.file) {
                this.addFileCourse({ ...event, folderName: "Profile" });
            }
        },
    },

    created() {
        this.getSettingYear();
        this.getSettingSemester();
        this.getSettings();
        this.getBranchList();
        this.getNamesUniversity("0");
        this.getDepartmentsWithSubjects("0"); // StudentType == 0
        this.getUserRoles();
        this.getSubjectsList({
            universityId: "",
            facultyId: "",
            yearId: "",
            semesterId: "",
            pageIndex: 1,
            pageSize: 1000,
        });
    },
};
</script>

<style>
#color {
    width: 30px !important;
    height: 30px !important;
}
</style>
